import { render, staticRenderFns } from "./VSelectPuc.vue?vue&type=template&id=33a49d13&scoped=true"
import script from "./VSelectPuc.vue?vue&type=script&lang=js"
export * from "./VSelectPuc.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33a49d13",
  null
  
)

export default component.exports